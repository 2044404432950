<template>
  <div class="kefu-page2 ">
    <div v-if="LiveChat">
      <LiveChatWidget
          :customerName="username"
          :customerEmail="username"
          :group="uid"
          :sessionVariables="session"
          :license="license"
          :visibility="maximized"
          v-on:visibility-changed="handleNewEvent"
          v-on:availability-changed="availabilityChanged"
          v-on:customer-status-changed="customerStatusChanged"
          v-on:form-submitted="formSubmitted"
          v-on:rating-submitted="ratingSubmitted"
          v-on:greeting-hidden="greetingHidden"
          v-on:greeting-displayed="greetingDisplayed"
      />
    </div>
  </div>

</template>
<script>
import { Button } from 'vant'
import { getKefuList } from '@/api/user'
import {LiveChatWidget} from "@livechat/widget-vue/v2";
import {getUserId} from "@/utils/auth";
export default {
  components: {
    LiveChatWidget,
    Button
  },
  data() {
    return {
      kefuList: [],
      kefuUrl: '',
      kfData: {},
      LiveChat: false,
      username: "",
      session: {},
      token: "",
      uid: "",
      maximized: "maximized",
      license: ""
    }
  },
  mounted() {
    this.username = window.localStorage.username
    this.uid = getUserId()
    this.init()
  },
  methods: {
    chatwoot(url,token){
      let that = this
      window.chatwootSDK.run({
        websiteToken: token,
        baseUrl: url
      });
      that.inter = setInterval(function(){
        if(document.getElementsByClassName("woot-widget-bubble")[0] !== undefined) {
          that.show = true;
          clearInterval(that.inter);
          console.log("window.localStorage.username")
          let js = {}
          try {
            js = JSON.parse(that.kfData.Remark)
          } catch (e) {
            console.log(e)
          }
          let obj = {
            name:"账号："+ that.kfData.Username + " 备注：" +that.kfData.UserRemark, // Name of the user
            email: "", // Email of the user
            Remark: that.kfData.UserRemark, // Email of the user
            // identifier_hash: that.kfData.IdentifierHash // Identifier Hash generated in the previous step
          }
          console.log(that.kfData.IdentifierHash)
          if(js.identifierHash !== undefined) {
            obj.identifier_hash = js.identifierHash
            window.$chatwoot.setUser(window.localStorage.username, obj);
          }
          console.log(obj)
          window.$chatwoot.toggle('open');
          // window.$chatwoot.toggleBubbleVisibility('hide');
        }
      },30);
    },
    init() {
      getKefuList().then(res => {
        this.kefuList = res.data.Items
        if(this.kefuList[0].Link.indexOf('lc.chat') > -1) {
          const regex = /\/(\d+)\//;
          const match = this.kefuList[0].Link.match(regex);
          if (match) {
            this.LiveChat = true
            this.license = match[1]
          }
          return
        }
        if (this.kefuList.length > 0) {
          this.kefuUrl = this.kefuList[0].Link
          this.kfData = this.kefuList[0]
            this.chatwoot(this.kefuUrl, this.kefuList[0].Token)
        }
      })
    },
    to(Type,Link){
      let tmp = undefined
      let that = this
      if(Type == "chatwoot") {
        if(window.$chatwoot === undefined) {
          tmp = setInterval(function (){
            // that.chatwoot()
            if (window.$chatwoot !== undefined) {
              clearInterval(tmp)
              window.$chatwoot.toggle('open');
            }
          },50)
        } else {
          window.$chatwoot.toggle('open');
        }
      } else {
        switch (Type) {
          case "wechat":
            Link = "weixin://" + Link
            break;
          case "qq":
            Link = "mqq://"+ Link
            break;
          case "whatsapp":
            Link = "whatsapp://send?phone="+ Link
            break;
          case "telegram":
            Link = "tg://resolve?domain="+ Link
            break;
          case "facebook":
            Link = "fb://"+ Link
            break;
          case "twitter":
            Link = "twitter://"+ Link
            break;
          case "instagram":
            Link = "instagram://"+ Link
            break;
            case "line":
            Link = "https://line.me/R/"+ Link
            break;
        }
        this.toPath(Link)
      }
    },
    toPath(path) {
      let a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    formSubmitted(event){
      console.log("formSubmitted", event)
    },
    ratingSubmitted(event){
      console.log("ratingSubmitted", event)
    },
    greetingHidden(event){
      console.log("greetingHidden", event)
    },
    greetingDisplayed(event){
      console.log("greetingDisplayed", event)
    },
    availabilityChanged(event){
      console.log("availabilityChanged", event)
    },
    visibilityChanged(event){
      console.log("visibilityChanged", event)
    },
    customerStatusChanged(event){
      console.log("customerStatusChanged", event)
      if (event.status === "browsing") {
        this.maximized = "hidden"
      }
    },
    handleNewEvent(event) {
      console.log('LiveChatWidget.onNewEvent', event)
      if(event.visibility === "hidden") {
        window.location.href = "/"
      }
      // minimized
      if(event.visibility === "minimized") {
        window.location.href = "/"
      }
    },
  }
}
</script>
<style>
.woot-widget-holder{
  height: calc(100vh - 110px) !important;
}
</style>